import renderHelpers from '../shared/render-helpers';
import regionsHelper from '../shared/regions-helper';

class AnalysisCompare {
  constructor(rootSelector) {
    this.$root = $(rootSelector);
    this.$metricFilter = this.$root.find('#compare__metric');
    this.$rangeFilter = this.$root.find('#compare__range');
    this.$dateRangeFilter = this.$root.find('#compare__date-range');
    this.$graph = this.$root.find('#compare__graph');
    this.$table = this.$root.find('#compare__table');
    this.url = `${location.protocol}//${location.host}${location.pathname}`;

    this.selectedRange = 'Weekly';
    this.startDate = null;
    this.endDate = null;

    this.currencyNamePlural = regionsHelper.getCurrencyNamePlural();

    this.table = null;
    this.graph = null;
    this.graphData = null;
    this.categories = null;
  }

  displayName(metric) {
    return _.chain(metric)
      .split('_')
      .reject((word) => {
        return word === 'cents';
      })
      .map((word) => {
        if (_.includes(['cogs'], word)) {
          return _.upperCase(word);
        } else if (word === 'acos') {
          return 'ACoS';
        } else {
          return _.capitalize(word);
        }
      })
      .join(' ')
      .value();
  }

  getTargets() {
    const targets = [];
    this.$metricFilter.find('option:selected').each(function () {
      targets.push($(this).val());
    });
    return targets;
  }

  isDollarMetric(metric) {
    return metric.endsWith('_cents');
  }

  isUnitMetric(metric) {
    return metric.endsWith('_units')
      || metric.endsWith('_quantity')
      || metric.endsWith('_qty')
      || _.includes([
        // Seller fields
        'total_units_ordered', // renamed from `units_ordered` to prevent AMS field conflict
        'total_order_items',
        'sessions',
        'page_views',
        'total_units_ordered_b2b',
        'total_order_items_b2b',
        'sessions_b2b',
        'page_views_b2b',

        // AMS fields
        'units_ordered',
      ], metric);
  }

  isPercentScaledMetric(metric) {
    return _.includes([
      'buy_box_percentage',
      'buy_box_percentage_b2b',
    ], metric);
  }

  yAxisIndex(seriesName) {
    if (this.isDollarMetric(seriesName)) {
      return 'dollars';
    } else if (this.isUnitMetric(seriesName)) {
      return 'units';
    } else if (this.isPercentScaledMetric(seriesName)) {
      return 'percentScaled';
    } else {
      return seriesName;
    }
  }

  removeAllSeries() {
    while (this.graph.series.length > 0) {
      this.graph.series[0].remove();
    }
  }

  addSeries(targets) {
    _.each(targets, (seriesName) => {
      const seriesData =
        seriesName === 'conversion_rate'
          ? this.graphData[seriesName].map((value) => parseFloat(value))
          : this.graphData[seriesName];

      this.graph.addSeries(
        {
          id: seriesName,
          name: this.displayName(seriesName),
          yAxis: this.yAxisIndex(seriesName),
          data: seriesData,
        },
        false,
      );
    });
  }

  removeSeries(targets) {
    _.each(targets, (seriesName) => {
      const index = _.findIndex(this.graph.series, function (s) {
        return s.userOptions.id === seriesName;
      });
      this.graph.series[index].remove();
    });
  }

  updateYAxis(targets) {
    // hide all axis
    _.each(this.graph.yAxis, (axis) => {
      axis.update({ visible: false }, false);
    });

    // show axis that correspond to series that are visible
    _.each(targets, (seriesName) => {
      if (this.isDollarMetric(seriesName)) {
        this.graph.get('dollars').update({ visible: true }, false);
      } else if (this.isUnitMetric(seriesName)) {
        this.graph.get('units').update({ visible: true }, false);
      } else if (this.isPercentScaledMetric(seriesName)) {
        this.graph.get('percentScaled').update({ visible: true }, false);
      } else {
        this.graph.get(seriesName).update({ visible: true }, false);
      }
    });
  }

  updateSeries(targets) {
    if (!this.graphData) return;
    const currentSeries = _.map(this.graph.series, (s) => {
      return s.userOptions.id;
    });
    const toAdd = _.difference(targets, currentSeries);
    const toRemove = _.difference(currentSeries, targets);
    this.addSeries(toAdd);
    this.removeSeries(toRemove);
    this.updateYAxis(targets);
    this.graph.redraw();
  }

  clearUI() {
    this.removeAllSeries();
    this.clearTable();
  }

  refreshData() {
    $.get(
      `${this.url}/data?range=${this.selectedRange}&startDate=${this.startDate}&endDate=${this.endDate}`,
      (data) => {
        this.setGraphData(data);
        this.setTableData(data);
        this.$metricFilter[0].dispatchEvent(new Event('change'));
      },
    );
  }

  setGraphData(data) {
    this.graphData = data;
    // Set xaxis to beginning_on data
    this.categories = data['beginning_on'];
    this.graph.xAxis[0].setCategories(this.categories, true);
  }

  setTableData(data) {
    const tableData = this.getDataForDataTables(data);
    const columns = this.getColumnsForDataTables(data);

    const buttons = [];
    const allowExports = window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables');

    if (allowExports) {
      buttons.push({
        extend: 'ilExports',
        title: 'Comparison Over Time',
      });
    }

    const dom = allowExports ? `
      <'dataTables_header flex items-center justify-end'B>
      <'dataTables_row'tr>
      <'dataTables_footer flex items-center justify-between'lp>
    ` : `
      <'dataTables_row'tr>
      <'dataTables_footer flex items-center justify-between'lp>
    `;

    this.table = this.$table.DataTable({
      dom: dom,
      buttons: buttons,
      destroy: true,
      data: tableData,
      pageLength: 55,
      paging: false,
      ordering: false,
      info: false,
      searching: false,
      columns: columns,
    });
  }

  clearTable() {
    if (this.table) {
      this.table.destroy();
      this.$table.empty();
    }
  }

  getDataForDataTables(data) {
    const tableData = [];
    _.each(data['beginning_on'], (date, index) => {
      const row = [];
      _.each(_.keys(data), (key) => {
        row.push(data[key][index]);
      });
      tableData.push(row);
    });
    return tableData;
  }

  getColumnsForDataTables(data) {
    return _.map(_.keys(data), (key) => {
      const title = this.displayName(key);
      const column = {};
      column['title'] = title;
      column['name'] = key;
      column['visible'] = key === 'beginning_on';
      if (key === 'beginning_on') {
        column['visible'] = true;
        column['width'] = 100;
      } else if (this.isDollarMetric(key)) {
        column['visible'] = false;
        column['render'] = renderHelpers.renderDollar;
      } else if (key === 'acos' || key == 'conversion_rate') {
        column['visible'] = false;
        column['render'] = renderHelpers.renderPercent;
      } else {
        column['visible'] = false;
        column['render'] = renderHelpers.renderNumber;
      }
      return column;
    });
  }

  showColumns(targets) {
    _.each(targets, (columnName) => {
      const column = this.table.column(`${columnName}:name`);
      column.visible(true);
    });
  }

  hideAllColumns() {
    this.table.columns().visible(false);
    this.table.column(`beginning_on:name`).visible(true);
  }

  updateTable(targets) {
    if (!this.table) return;
    this.hideAllColumns();
    this.showColumns(targets);
  }

  initGraph() {
    const self = this;
    self.graph = Highcharts.chart(self.$graph[0], {
      chart: {
        zoomType: 'x',
        reflow: false,
      },
      boost: {
        seriesThreshold: 0,
      },
      title: {
        text: 'Comparison Over Time',
      },
      xAxis: [
        {
          title: {
            text: 'Beginning On',
          },
          labels: {
            formatter: function () {
              return this.value;
            },
          },
        },
      ],
      yAxis: [
        {
          id: 'dollars',
          title: {
            text: self.currencyNamePlural,
          },
          labels: {
            formatter: function () {
              return renderHelpers.renderDollarShort(this.value);
            },
          },
          visible: false,
        },
        {
          id: 'units',
          title: {
            text: 'Units',
          },
          labels: {
            formatter: function () {
              return renderHelpers.renderNumberShort(this.value);
            },
          },
          visible: false,
          opposite: true,
        },
        {
          id: 'clicks',
          title: {
            text: 'Clicks',
          },
          labels: {
            formatter: function () {
              return renderHelpers.renderNumberShort(this.value);
            },
          },
          visible: false,
          opposite: true,
        },
        {
          id: 'impressions',
          title: {
            text: 'Impressions',
          },
          labels: {
            formatter: function () {
              return renderHelpers.renderNumberShort(this.value);
            },
          },
          visible: false,
          opposite: true,
        },
        {
          id: 'conversions',
          title: {
            text: 'Conversions',
          },
          labels: {
            formatter: function () {
              return renderHelpers.renderNumberShort(this.value);
            },
          },
          visible: false,
          opposite: true,
        },
        {
          id: 'acos',
          title: {
            text: 'ACoS',
          },
          labels: {
            formatter: function () {
              return renderHelpers.renderPercent(this.value);
            },
          },
          visible: false,
          opposite: true,
        },
        {
          id: 'conversion_rate',
          title: {
            text: 'Conversion Rate',
          },
          labels: {
            formatter: function () {
              return renderHelpers.renderPercent(this.value);
            },
          },
          visible: false,
          opposite: true,
        },
        {
          id: 'percentScaled',
          title: {
            text: 'Percentage',
          },
          labels: {
            formatter: function () {
              return renderHelpers.renderPercentScaled(this.value);
            },
          },
          visible: false,
          opposite: true,
        },
      ],
      tooltip: {
        formatter: function () {
          let tooltip = `<b>${this.series.name}</b><br/>`;

          if (self.isDollarMetric(this.series.userOptions.id)) {
            tooltip += `${this.x}: ${renderHelpers.renderDollar(this.y)} (Dollars)`;
          } else if (self.isUnitMetric(this.series.userOptions.id)) {
            tooltip += `${this.x}: ${renderHelpers.renderNumber(this.y)} (Units)`;
          } else if (this.series.userOptions.id === 'acos' || this.series.userOptions.id === 'conversion_rate') {
            tooltip += `${this.x}: ${renderHelpers.renderPercent(this.y)}`;
          } else if (self.isPercentScaledMetric(this.series.userOptions.id)) {
            tooltip += `${this.x}: ${renderHelpers.renderPercentScaled(this.y)}`;
          } else {
            tooltip += `${this.x}: ${renderHelpers.renderNumber(this.y)}`;
          }
          return tooltip;
        },
      },
    });
  }

  initFilters() {
    this.datePicker = this.$dateRangeFilter
      .daterangepicker({
        showWeekNumbers: true,
        ranges: {
          'Year to Date': [moment().startOf('year'), moment().endOf('day')],
          'Last 12 weeks': [
            moment().subtract(12, 'weeks').startOf('week'),
            moment().subtract(1, 'weeks').endOf('week'),
          ],
          'Last 24 weeks': [
            moment().subtract(24, 'weeks').startOf('week'),
            moment().subtract(1, 'weeks').endOf('week'),
          ],
          'Last 12 months': [
            moment().subtract(12, 'months').startOf('month'),
            moment().subtract(1, 'months').endOf('month'),
          ],
          'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
        },
        showCustomRangeLabel: false,
        alwaysShowCalendars: true,
        maxDate: moment(),
        opens: 'left',
      })
      .data('daterangepicker');

    this.$dateRangeFilter.on('apply.daterangepicker', (e, picker) => {
      // Need to make sure to clone moment() objects otherwise we will be actually changing
      // moment() obj defined in "ranges" and it'll mess up future date calculations
      const start = picker.startDate.clone();
      const end = picker.endDate.clone();

      const adjustedStart = this.selectedRange === 'Monthly' ? start.startOf('month') : start.startOf('week');
      const adjustedEnd = this.selectedRange === 'Monthly' ? end.endOf('month') : end.endOf('week');

      this.datePicker.setStartDate(adjustedStart);
      this.datePicker.setEndDate(adjustedEnd);
      this.startDate = adjustedStart.format('YYYY-MM-DD');
      this.endDate = adjustedEnd.format('YYYY-MM-DD');
      this.clearUI();
      this.refreshData();
    });

    this.$metricFilter.on('change', () => {
      const targets = this.getTargets();
      this.updateSeries(targets);
      this.updateTable(targets);
    });

    this.$rangeFilter.on('change', () => {
      this.selectedRange = $(this.$rangeFilter.find('option:selected')[0]).val();
      this.clearUI();
      this.refreshData();
    });
  }

  // set the initial values that were passed in via the query params.
  setInitFilterValues() {
    const range = TORO.shared.getParameterByName('range');

    const startDate =
      range === 'Monthly'
        ? moment().subtract(12, 'months').startOf('month')
        : moment().subtract(24, 'weeks').startOf('week');
    const endDate =
      range === 'Monthly' ? moment().subtract(1, 'months').endOf('month') : moment().subtract(1, 'weeks').endOf('week');

    this.datePicker.setStartDate(startDate);
    this.datePicker.setEndDate(endDate);
    this.startDate = startDate.format('YYYY-MM-DD');
    this.endDate = endDate.format('YYYY-MM-DD');

    if (range) {
      this.$rangeFilter.val(range);
      this.$rangeFilter[0].dispatchEvent(new Event('change'));
      this.selectedRange = $(this.$rangeFilter.find('option:selected')[0]).val();
    }

    const metrics = TORO.shared.getParameterByName('metrics');
    if (metrics) {
      this.$metricFilter.val(metrics.split(','));
      this.$metricFilter[0].dispatchEvent(new Event('change'));
    }
  }

  init() {
    this.initGraph();
    this.initFilters();
    this.setInitFilterValues();
    this.refreshData();
  }
}

export default AnalysisCompare;
