class AccountSelector {
  constructor(rootSelector) {
    this.root = document.querySelector(rootSelector);
  }

  accountPath(orgToken) {
    // pass pathname to server
    return `/accounts/${orgToken}?pathname=${window.location.pathname}`;
  }

  init() {
    this.root.addEventListener('change', (evt) => {
      const options = Array.from(this.root.querySelectorAll('option'));
      const selected = options.find((opt) => opt.selected);
      const orgName = selected.innerText.trim();
      const orgToken = this.root.value;

      mixpanel.track('Account Selector Clicked', {
        'Organization Token': orgToken,
        'Organization Name': orgName,
        pathname: window.location.pathname,
      });

      window.location.href = this.accountPath(orgToken);
    });
  }
}

export default AccountSelector;
