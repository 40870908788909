import React from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Datatable from "./datatable";

class TagsShow extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: false,
      asinList: "",
      result: null,
    };
  }

  componentDidMount() {}

  handleTagProducts = (e) => {
    this.setState({ showModal: true });
  };

  handleModalHide = (e) => {
    e.preventDefault();
    this.setState({ showModal: false, asinList: "", result: null });
  };

  handleInputChange = (e) => {
    this.setState({ asinList: e.target.value });
  };

  tagProducts = (e) => {
    const { organization, tag } = this.props;
    const { asinList } = this.state;
    const data = { asins: asinList };

    axios.post(`/accounts/${organization.token}/settings/tags/${tag.token}/add_products`, data).then((response) => {
      this.setState({
        result: response.data,
      });
    });
    this.mixpanelBulkAdd();
  };

  mixpanelBulkAdd() {
    const { token, name } = this.props.organization;
    const { tag } = this.props;
    mixpanel.track("Tag - Bulk Tag Products", {
      "Organization Token": token,
      "Organization Name": name,
      Tag: tag,
    });
  }

  render() {
    const { tag, organization, canUpdate } = this.props;
    const { showModal, asinList, result } = this.state;

    return (
      <>
        {canUpdate && (
          <div className="relative">
            <div className="mb-8 md:absolute md:right-0 md:bottom-0">
              <button className="button" onClick={this.handleTagProducts}>
                Tag Products
              </button>
            </div>
          </div>
        )}
        <div className="panel">
          <div className="panel-body">
            <Datatable organization={organization} tag={tag} result={result} canUpdate={canUpdate} />
          </div>

          <Modal show={showModal} onHide={this.handleModalHide} backdrop="static">
            <Modal.Header closeButton>Tag Products</Modal.Header>
            <Modal.Body>
              {result ? (
                <div className="prose">
                  <p className="font-medium">{result.found_asins.length} product(s) tagged!</p>
                  {result.missing_asins.length > 0 && (
                    <>
                      <p>The following ASIN(s) were not found:</p>
                      <ul>
                        {result.missing_asins.map((asin) => (
                          <li className="my-1">{asin}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <div className="prose mb-4 text">
                    <p>
                      Add Product ASINs you'd like to be tagged as '<strong>{tag.name}</strong>'.
                      <br />
                      One ASIN per line.
                    </p>
                  </div>

                  <div className="form-control">
                    <textarea
                      placeholder="ASIN1&#10;ASIN2&#10;ASIN3"
                      autoFocus
                      rows="10"
                      value={asinList}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="button-group">
                {result ? (
                  <button className="button secondary" onClick={this.handleModalHide}>
                    Done
                  </button>
                ) : (
                  <>
                    <button className="button secondary bordered" onClick={this.handleModalHide}>
                      Cancel
                    </button>
                    <button className="button" onClick={this.tagProducts}>
                      Tag Products
                    </button>
                  </>
                )}
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default TagsShow;
