class WeeklyReportsSellableOnHandRevenueTable {
  constructor(rootSelector) {
    this.$table = $(rootSelector);
  }
  init() {
    const buttons = [];
    const exportAllowed = window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables');

    if (exportAllowed) {
      buttons.push({
        extend: 'ilExports',
        filename: 'Weekly Sellable On Hand Revenue',
      });
    }

    const dom = exportAllowed ? `
      <'dataTables_header flex items-center justify-end'B>
      <'dataTables_row'tr>
    ` : `
      <'dataTables_header flex items-center justify-end min-h-20'>
      <'dataTables_row'tr>
    `;

    this.$table.DataTable({
      paging: false,
      ordering: false,
      scrollX: false,
      dom: dom,
      bAutoWidth: false,
      buttons: buttons,
    });
  }
}

export default WeeklyReportsSellableOnHandRevenueTable;
