import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class Finances {
  constructor(rootSelector) {
    this.$root = $(rootSelector);
    this.$table = $('#finances__table');
    this.helpers = renderHelpers;
  }

  init() {
    this.defineTable();
    this.initTable();
  }

  defineTable() {
    this.definition = new DataTableDefinition({
      defaultSortByColumns: ['transactions_count'],
      defaultSortByDirections: ['desc'],
      columns: [
        {
          name: 'transaction_type',
          data: 'transaction_type',
          render: this.helpers.renderTitle,
        },
        {
          name: 'transactions_count',
          data: 'transactions_count',
        },
        {
          name: 'sum_total_amount_cents',
          data: 'sum_total_amount_cents',
          render: this.helpers.renderDollar,
        },
        {
          name: 'marketplace_names',
          data: 'marketplace_names',
        },
      ],
    });
  }

  initTable() {
    const buttons = [{
      extend: 'ilColvis',
      columns: this.definition.columnIndexes([
        'transaction_type',
        'transactions_count',
        'sum_total_amount_cents',
        'marketplace_names',
      ]),
    }];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilExports',
        filename: 'Finances',
        index: 1,
      });
    }

    this.dataTable = this.$table.DataTable({
      paging: true,
      pageLength: 25,
      ajax: {
        url: location.pathname + '.json',
        dataSrc: '', // Default - no manipulation to response data
      },
      processing: true,
      deferRender: true,
      dom: TORO.shared.dataTableDefaults.dom,
      language: TORO.shared.dataTableDefaults.language('finances'),
      buttons: buttons,
      order: this.definition.order(),
      columns: this.definition.columns,
    });
  }
}

export default Finances;
