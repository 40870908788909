import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class ProductsSeller {
  constructor(rootSelector) {
    this.helpers = renderHelpers;
    this.$table = $(rootSelector);
  }

  set_column_name() {
    this.$table = $('#seller-products-table');
    const wrapper = $('#seller-products-table_wrapper');
    if (this.$rangeFilter.val() === 'Weekly') {
      wrapper.find('.set-period').text('Week');
    } else if (this.$rangeFilter.val() === 'Monthly') {
      wrapper.find('.set-period').text('Month');
    } else {
      wrapper.find('.set-period').text('Yearly');
    }
  }

  init() {
    const self = this;

    $.urlParam = function (name) {
      var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
      var res = results ? results[1] : '';
      return res;
    };

    // range selector (weekly or monthly)
    this.$rangeFilter = $('#products-range-filter');

    // period selector
    this.$periodFilter = $('#products-period-filter');

    // Regular vs. B2B selector
    this.$b2bFilter = $('#products-b2b-filter');

    if ($.urlParam('range')) {
      this.$rangeFilter.val($.urlParam('range'));
      this.$rangeFilter[0].dispatchEvent(new Event('change'));
      this.$periodFilter.val($.urlParam('range') + '|' + $.urlParam('beginning_on'));
      this.$periodFilter[0].dispatchEvent(new Event('change'));
    }

    if (!this.$rangeFilter.val()) {
      this.$rangeFilter.val('Monthly');
      this.$rangeFilter[0].dispatchEvent(new Event('change'));
    }

    if ($.urlParam('b2b')) {
      this.$b2bFilter.val('b2b');
      this.$b2bFilter[0].dispatchEvent(new Event('change'));
    }

    this.set_column_name();

    var definition = new DataTableDefinition({
      defaultSortByColumns: ['shipped_cogs_cents'],
      defaultSortByDirections: ['desc'],
      columns: [
        {
          name: 'asin',
          data: 'asin',
          className: 'numeric',
          render: self.helpers.renderAsinLink,
        },
        {
          name: 'main_image_thumb',
          data: 'main_image_thumb',
          class: 'w-16 px-1',
          sortable: false,
          render: self.helpers.renderProductThumbnail,
        },
        {
          name: 'asin_name',
          data: function (row, type, val, meta) {
            return (
              row.asin_name || row.amazon_product_title || '<span class="italic text-text/50">No name found</span>'
            );
          },
          className: 'min-w-96',
          render: self.helpers.renderProductName,
        },
        {
          name: 'track_amazon_com',
          data: 'track_amazon_com',
          visible: false,
        },
        {
          name: 'upc',
          data: 'upc',
          className: 'numeric',
        },
        {
          name: 'model_number',
          data: 'model_number',
          className: 'numeric',
          render: function (data, type, row) {
            const modelNumber = row.custom_model_number || data;
            return modelNumber;
          },
        },
        {
          name: 'tag_name',
          data: 'tag_name',
          className: 'min-w-48',
          render: self.helpers.renderTag,
        },
        {
          name: 'product_group',
          data: 'product_group',
          visible: false,
        },
        {
          name: 'category',
          data: 'category',
          visible: false,
        },
        {
          name: 'subcategory',
          data: 'subcategory',
          visible: false,
        },
        {
          name: 'units_ordered',
          data: 'units_ordered',
          render: function (data, type, row) {
            return self.helpers.renderNumberWithMetricChangePercent(data, row.units_ordered_change);
          },
        },
        {
          name: 'units_ordered_b2b',
          data: 'units_ordered_b2b',
          render: function (data, type, row) {
            return self.helpers.renderNumberWithMetricChangePercent(data, row.units_ordered_b2b_change);
          },
        },
        {
          name: 'ordered_product_sales_cents',
          data: 'ordered_product_sales_cents',
          render: self.helpers.renderDollar,
        },
        {
          name: 'ordered_product_sales_cents_change',
          data: 'ordered_product_sales_cents_change',
          render: self.helpers.renderMetricChangePercent,
          sortable: false,
        },
        {
          name: 'ordered_product_sales_cents_b2b',
          data: 'ordered_product_sales_cents_b2b',
          render: self.helpers.renderDollar,
        },
        {
          name: 'ordered_product_sales_cents_b2b_change',
          data: 'ordered_product_sales_cents_b2b_change',
          render: self.helpers.renderMetricChangePercent,
          sortable: false,
        },
        {
          name: 'total_order_items',
          data: 'total_order_items',
          render: function (data, type, row) {
            return self.helpers.renderNumberWithMetricChangePercent(data, row.total_order_items_change);
          },
        },
        {
          name: 'total_order_items_b2b',
          data: 'total_order_items_b2b',
          render: function (data, type, row) {
            return self.helpers.renderNumberWithMetricChangePercent(data, row.total_order_items_b2b_change);
          },
        },
        {
          name: 'buy_box_percentage',
          data: 'buy_box_percentage',
          render: self.helpers.renderPercentScaled,
          sortable: false,
        },
        {
          name: 'buy_box_percentage_b2b',
          data: 'buy_box_percentage_b2b',
          render: self.helpers.renderPercentScaled,
          sortable: false,
        },
        {
          name: 'unit_session_percentage',
          data: 'unit_session_percentage',
          render: self.helpers.renderPercentScaled,
          sortable: false,
        },
        {
          name: 'unit_session_percentage_b2b',
          data: 'unit_session_percentage_b2b',
          render: self.helpers.renderPercentScaled,
          sortable: false,
        },
        {
          name: 'sessions',
          data: 'sessions',
          sortable: false,
          render: function (data, type, row) {
            if (row.browser_sessions || row.mobile_app_sessions) {
              const browserSessionsWidth = row.browser_sessions / row.sessions * 100;
              const totalPercent = self.helpers.renderPercentScaled(row.session_percentage);
              const browserPercent = self.helpers.renderPercentScaled(row.browser_session_percentage);
              const mobileAppPercent = self.helpers.renderPercentScaled(row.mobile_app_session_percentage);
              return `
                <span class="flex gap-4 justify-between">
                  <span title="Session %">
                    ${self.helpers.renderNumber(data)}
                    <br>
                    <span class="text-xs">${totalPercent}</span>
                  </span>

                  <span class="w-32 flex flex-col justify-between">
                    <span class="my-1 h-2 flex bg-accent-3 rounded overflow-hidden">
                      <span
                        class="bg-accent-2"
                        style="width: ${browserSessionsWidth}%;"
                        title="Browser Sessions\n${self.helpers.renderNumber(row.browser_sessions)} (${browserPercent})"
                      >
                      </span>
                      <span
                        class="flex-1"
                        title="Mobile App Sessions\n${self.helpers.renderNumber(row.mobile_app_sessions)} (${mobileAppPercent})"
                      >
                      </span>
                    </span>
                    <span class="flex justify-between">
                      <span class="text-xs opacity-50" title="Browser Session %">${browserPercent}</span>
                      <span class="text-xs opacity-50" title="Mobile App Session %">${mobileAppPercent}</span>
                    </span>
                  </span>
                </span>
              `;
            } else {
              return self.helpers.renderNumber(data);
            }
          },
        },
        {
          name: 'sessions_b2b',
          data: 'sessions_b2b',
          sortable: false,
          render: function (data, type, row) {
            if (row.browser_sessions_b2b || row.mobile_app_sessions_b2b) {
              const browserSessionsWidth = row.browser_sessions_b2b / row.sessions_b2b * 100;
              const totalPercent = self.helpers.renderPercentScaled(row.session_percentage_b2b);
              const browserPercent = self.helpers.renderPercentScaled(row.browser_session_percentage_b2b);
              const mobileAppPercent = self.helpers.renderPercentScaled(row.mobile_app_session_percentage_b2b);
              return `
                <span class="flex gap-4 justify-between">
                  <span title="Session %">
                    ${self.helpers.renderNumber(data)}
                    <br>
                    <span class="text-xs">${totalPercent}</span>
                  </span>

                  <span class="w-32 flex flex-col justify-between">
                    <span class="my-1 h-2 flex bg-accent-3 rounded overflow-hidden">
                      <span
                        class="bg-accent-2"
                        style="width: ${browserSessionsWidth}%;"
                        title="Browser Sessions\n${self.helpers.renderNumber(row.browser_sessions_b2b)} (${browserPercent})"
                      >
                      </span>
                      <span
                        class="flex-1"
                        title="Mobile App Sessions\n${self.helpers.renderNumber(row.mobile_app_sessions_b2b)} (${mobileAppPercent})"
                      >
                      </span>
                    </span>
                    <span class="flex justify-between">
                      <span class="text-xs opacity-50" title="Browser Session %">${browserPercent}</span>
                      <span class="text-xs opacity-50" title="Mobile App Session %">${mobileAppPercent}</span>
                    </span>
                  </span>
                </span>
              `;
            } else {
              return self.helpers.renderNumber(data);
            }
          },
        },
        {
          name: 'page_views',
          data: 'page_views',
          sortable: false,
          render: function (data, type, row) {
            if (row.browser_page_views || row.mobile_app_page_views) {
              const browserViewsWidth = row.browser_page_views / row.page_views * 100;
              const totalPercent = self.helpers.renderPercentScaled(row.page_views_percentage);
              const browserPercent = self.helpers.renderPercentScaled(row.browser_page_views_percentage);
              const mobileAppPercent = self.helpers.renderPercentScaled(row.mobile_app_page_views_percentage);
              return `
                <span class="flex gap-4 justify-between">
                  <span title="Page View %">
                    ${self.helpers.renderNumber(data)}
                    <br>
                    <span class="text-xs">${totalPercent}</span>
                  </span>

                  <span class="w-32 flex flex-col justify-between">
                    <span class="my-1 h-2 flex bg-accent-3 rounded overflow-hidden">
                      <span
                        class="bg-accent-2"
                        style="width: ${browserViewsWidth}%;"
                        title="Browser Page Views\n${self.helpers.renderNumber(row.browser_page_views)} (${browserPercent})"
                      >
                      </span>
                      <span
                        class="flex-1"
                        title="Mobile App Page Views\n${self.helpers.renderNumber(row.mobile_app_page_views)} (${mobileAppPercent})"
                      >
                      </span>
                    </span>
                    <span class="flex justify-between">
                      <span class="text-xs opacity-50" title="Browser Page View %">${browserPercent}</span>
                      <span class="text-xs opacity-50" title="Mobile App Page View %">${mobileAppPercent}</span>
                    </span>
                  </span>
                </span>
              `;
            } else {
              return self.helpers.renderNumber(data);
            }
          },
        },
        {
          name: 'page_views_b2b',
          data: 'page_views_b2b',
          sortable: false,
          render: function (data, type, row) {
            if (row.browser_page_views_b2b || row.mobile_app_page_views_b2b) {
              const browserViewsWidth = row.browser_page_views_b2b / row.page_views_b2b * 100;
              const totalPercent = self.helpers.renderPercentScaled(row.page_views_percentage_b2b);
              const browserPercent = self.helpers.renderPercentScaled(row.browser_page_views_percentage_b2b);
              const mobileAppPercent = self.helpers.renderPercentScaled(row.mobile_app_page_views_percentage_b2b);
              return `
                <span class="flex gap-4 justify-between">
                  <span title="Page View %">
                    ${self.helpers.renderNumber(data)}
                    <br>
                    <span class="text-xs">${totalPercent}</span>
                  </span>

                  <span class="w-32 flex flex-col justify-between">
                    <span class="my-1 h-2 flex bg-accent-3 rounded overflow-hidden">
                      <span
                        class="bg-accent-2"
                        style="width: ${browserViewsWidth}%;"
                        title="Browser Page Views\n${self.helpers.renderNumber(row.browser_page_views_b2b)} (${browserPercent})"
                      >
                      </span>
                      <span
                        class="flex-1"
                        title="Mobile App Page Views\n${self.helpers.renderNumber(row.mobile_app_page_views_b2b)} (${mobileAppPercent})"
                      >
                      </span>
                    </span>
                    <span class="flex justify-between">
                      <span class="text-xs opacity-50" title="Browser Page View %">${browserPercent}</span>
                      <span class="text-xs opacity-50" title="Mobile App Page View %">${mobileAppPercent}</span>
                    </span>
                  </span>
                </span>
              `;
            } else {
              return self.helpers.renderNumber(data);
            }
          },
        },
      ],
    });

    const buttons = [{
      extend: 'ilColvis',
      columns: definition.columnIndexes([
        'asin',
        'asin_name',
        'track_amazon_com',
        'upc',
        'model_number',
        'tag_name',
        'product_group',
        'category',
        'subcategory',
      ]),
    }];

    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilCsvServer',
        url: TORO.shared.urlHostPathname().replace('/seller', '') + '/datatables.csv',
      });
    }

    var table = this.$table.DataTable({
      serverSide: true,
      processing: true,
      info: true,
      ajax: {
        data: (d) => {
          // Send the values Range and Period to the backend.
          // In the form of "Weekly|2018-06-03",
          d['range_period'] = self.$periodFilter.val();
        },
        url: TORO.shared.urlHostPathname().replace('/seller', '') + '/datatables',
        type: 'POST',
        error: function (xhr, error, thrown) {
          // Handle requests that are being cancelled because of refresh / unloading
          // https://stackoverflow.com/questions/699941/handle-ajax-error-when-a-user-clicks-refresh/18170879#18170879
          // Various solutions. For now just ignore, since its typically a false
          // positive right now...
        },
      },
      paging: true,
      pageLength: 25,
      dom: "<'dataTables_header border-b-0'fB><'dataTables_body border-b border-border'i><'dataTables_body'tr><'dataTables_footer'p>",
      bAutoWidth: false,
      deferRender: true,
      language: {
        ...TORO.shared.dataTableDefaults.language('products'),
        info: 'Showing _START_ to _END_ of _TOTAL_ products this period',
        infoEmpty: 'No products found for this period',
        emptyTable: 'No products found for this period',
      },
      buttons: buttons,
      columns: definition.columns,
    });

    // Clear the "period" select dropdown
    function emptyPeriodOptions() {
      self.$periodFilter.empty();
    }

    // Get "period" options for the server and load them into the select dropdown
    function getPeriodOptions(range, callback) {
      $.get('report_' + range, (data) => {
        var options = data.data;

        _.each(options, (item) => {
          var text = item.split('|')[0];
          var value = item.split('|')[1];
          var month_or_week = self.$rangeFilter.val();
          self.$periodFilter.append("<option value='" + month_or_week + '|' + value + "'>" + text + '</option>');
        });

        callback(options);
      });
    }

    // When we first load the "range" dropdown, load the period dropdown and set
    // the select item based off the query param.
    emptyPeriodOptions();
    getPeriodOptions(self.$rangeFilter.val().toLowerCase(), function (options) {
      // If there was a range passed in via the URL, set the range and beginning_on value
      // Otherwise default the first value for period
      if ($.urlParam('range')) {
        self.$periodFilter.val($.urlParam('range') + '|' + $.urlParam('beginning_on'));
      } else if (options[0]) {
        self.$periodFilter.val(self.$rangeFilter.val() + '|' + options[0].split('|')[1]);
      }

      self.$periodFilter[0].dispatchEvent(new Event('change'));
      self.set_column_name();
    });

    // On "range" change, get the new period dropdown
    self.$rangeFilter.on('change', function () {
      emptyPeriodOptions();
      getPeriodOptions(this.value.toLowerCase(), function (options) {
        // if there is an option, default to the first one.
        if (options[0]) {
          self.$periodFilter.val(self.$rangeFilter.val() + '|' + options[0].split('|')[1]);
          self.$periodFilter[0].dispatchEvent(new Event('change'));
        }
        self.set_column_name();
      });
    });

    // ok when the "Show Column" button is clicked, check if we are weekly / monthly
    // and change the text in the dropdown.
    // I wonder if there is an alternative to doing it via nth-child...
    $('#seller-products-table_wrapper')
      .find('.buttons-collection.buttons-colvis')
      .click(function () {
        var $dropdown = $('.dt-button-collection');
        var unitsIndex = definition.columnIndex('last_shipped_units');
        if (self.$rangeFilter.val() === 'Weekly') {
          $dropdown
            .find('.dt-button:nth-child(' + unitsIndex + ')')
            .find('span')
            .text('Last Week Shipped Units');
        } else if (self.$rangeFilter.val() === 'Monthly') {
          $dropdown
            .find('.dt-button:nth-child(' + unitsIndex + ')')
            .find('span')
            .text('Last Month Shipped Units');
        } else {
          // Yearly
          $dropdown
            .find('.dt-button:nth-child(' + unitsIndex + ')')
            .find('span')
            .text('Last Year Shipped Units');
        }
      });

    // When any link is clicked, self.set_column_name();
    // Not 100% sure why this is necessary.
    $('body').click(function (e) {
      if (e.target.tagName === 'A') self.set_column_name();
    });

    // when the period selector is changed, refresh the table.
    self.$periodFilter.on('change', function (e) {
      if (this.value) {
        table.draw();
      }
    });

    // Hide b2b columns by default.
    table.columns('.js-filter-b2b').visible(false);
    table.columns('.js-filter-regular').visible(true);

    // When the B2B filter changes, update the displayed columns
    self.$b2bFilter.on('change', function () {
      var columnsShown = self.$b2bFilter.val() === 'b2b' ? '.js-filter-b2b' : '.js-filter-regular';
      var columnsHidden = self.$b2bFilter.val() === 'b2b' ? '.js-filter-regular' : '.js-filter-b2b';
      console.log(columnsShown, table.columns(columnsShown));
      table.columns(columnsHidden).visible(false);
      table.columns(columnsShown).visible(true);
    });
  }
}

export default ProductsSeller;
