import * as d3 from 'd3';

export interface Datum {
  label: string;
  value: number;
  color: string;
  order: number;
}

export default class UiPieChart extends HTMLElement {
  data: Datum[] = [];

  constructor() {
    super();
    this.draw();
  }

  draw = () => {
    while (this.children.length > 0) this.removeChild(this.children[0]);

    this.data = JSON.parse(this.getAttribute('data') || '{}');

    const w = 100, h = w, r = w / 2;

    const arc = d3.arc<any, d3.PieArcDatum<Datum>>()
      .innerRadius(r * 0.5)
      .outerRadius(r - 1);

    const pie = d3.pie<any, Datum>()
      // .padAngle(2 / r)
      .sort((a, b) => a.order - b.order)
      .value(d => d.value);

    const svg = d3.create('svg')
      .attr('width', w)
      .attr('height', h)
      .attr('viewBox', [-w / 2, -h / 2, w, h])
      .attr('style', 'max-width: 100%; height: auto;');

    svg.append('g')
      .selectAll()
      .data(pie(this.data))
      .join('path')
      .attr('fill', d => d.data.color)
      .attr('stroke', 'white')
      .attr('stroke-width', '1px')
      .attr('d', arc)
      .append('title')
      .text(d => d.data.label);

    this.appendChild(svg.node());
  }

  connectedCallback() {
    this.draw();
  }

  disconnectedCallback() {
    //
  }

  adoptedCallback() {
    this.draw();
  }

  attributeChangedCallback() {
    this.draw();
  }
}
