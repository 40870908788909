import regionsHelper from './regions-helper.js';

// Values extracted from AmazonProductChange.ATTRIBUTE_NAMES.
// Please keep this updated at all times.
const ATTRIBUTE_NAMES = {
  title: 'Title',
  subtitle: 'Subtitle',
  feature_bullets: 'Feature Bullets',
  product_description: 'Product Description',
  rich_product_description: 'Rich Product Description',
  rich_product_information: 'Rich Product Information',
  image_urls: 'Images',
  a_plus: 'A+ Content',
  a_plus_pd_html: 'A+ Product Description',
  a_plus_batch_html: 'A+ Batch',
  a_plus_brand_story_html: 'A+ Brand Story',
  a_plus_sustainability_html: 'A+ Sustainability',
  book_description_html: 'Book Description',
  more_reasons_to_read_title: 'More Reasons to Read Title',
  more_reasons_to_read: 'More Reasons to Read',
  editorial_reviews_html: 'Editorial Reviews',
  variations: 'Variations',
  amazon_choice_for: 'Amazon Choice',
  best_seller_in: 'Best Seller',
  best_seller_ranks: 'Best Seller Ranks',
  amazon_charts_for: 'Amazon Charts',
  bought_in_past_week: 'Bought in Past Week',
  social_proofing_faceout: 'Social Proofing (Faceout)',
  product_overview: 'Product Overview',
  product_overview_glance_icons: 'Product Overview - Glance Icons',
  variation_asins: 'Variations',
  climate_pledge_friendly: 'Climate Pledge Friendly',
  provenance_certifications: 'Provenance Certifications',
  buying_option_nostos_badge: 'Frequently Returned Item'
}

function asinLink(asinUrl, asin, anchor) {
  var url = asinUrl + '?asin=' + asin;
  if (anchor) {
    url += '&anchor=' + anchor;
  }
  return url;
}

function renderAsinLink(asin, type, row) {
  const link = asinLink(`/accounts/${row['account_token']}/products/asin`, asin);
  return `<a class="link" href="${link}">${asin}</a>`;
}

function renderTitle(data, type, row) {
  return _.capitalize(_.lowerCase(data));
}

// Assumes values are in cents
function renderDollar(data, good = null) {
  if (data === null || data === undefined) {
    return '-';
  } else {
    let statusClass = '';
    if (good === true) statusClass = ' good';
    if (good === false) statusClass = ' bad';

    const symbol = regionsHelper.getCurrencySymbol();
    return `
      <span class="amount full-width${statusClass}">
        <span class="symbol">${symbol}</span>
        <span class="value">${numeral((data / 100).toFixed(2)).format(`0,0.00`)}</span>
      </span>
    `;
  }
}

// Assumes values are in cents
// Render a truncated version for labels e.g. 1,000,000 becomes $1.0 M
function renderDollarShort(data) {
  const symbol = regionsHelper.getCurrencySymbol();
  return `${symbol}${numeral((data / 100).toFixed(2)).format(`0[.]0a`)}`;
}

function renderNumber(data) {
  if (data === null || data === undefined) {
    return '-';
  } else {
    return numeral(data).format('0,0');
  }
}

// Render a truncated version for labels e.g. 1,000,000 becomes 1.0 M
function renderNumberShort(data) {
  return numeral(data).format('0[.]0 a');
}

function renderPercent(data) {
  return numeral(data).format('0.00%');
}

function renderPercentScaled(data) {
  return numeral(data / 100.0).format('0.00%');
}

function renderDate(data) {
  return moment(data).format('YYYY-MM-DD');
}

function renderIssueType(data) {
  switch (data) {
    case 'asn':
      return 'ASN';
    case 'transportation':
      return 'Transportation';
    case 'po':
      return 'PO';
    case 'received':
      return 'Received';
    case 'prep':
      return 'Prep';
    case 'direct_fulfillment':
      return 'Direct Fulfillment';
    case 'unknown':
      return 'Unknown';
  }
}

function renderTag(data, type, row) {
  if (type === 'export') {
    return data || '-';
  } else if (data) {
    const tags = _.map(data.split('|'), function (tag) {
      return '<span class="tag">' + _.escape(tag) + '</span>';
    }).join('\n');

    return `<div class="tags-container">${tags}</div>`;
  } else {
    return '-';
  }
}

// Basically the same as renderTag except skip escaping tag because it was
// already done on the backend.
function renderTagUnescaped(data, type, row) {
  if (data) {
    return _.map(data.split('|'), function (tag) {
      return '<span class="tag-sm">' + tag + '</span>';
    }).join(' ');
  } else {
    return '-';
  }
}

function renderAttribute(data, type, row) {
  if (data in ATTRIBUTE_NAMES) return ATTRIBUTE_NAMES[data];
  return _.chain(data).replace(/_/g, ' ').replace('cents', '').upperFirst().value();
}

function renderAttributeAction(data, type, row) {
  return _.chain(data).replace(/_/g, ' ').upperFirst().value();
}

function renderSeller(data, type, row) {
  if (row.seller_link) return `
    <a class="link" href="${row.seller_link}" target="_blank" rel="noreferrer">
      ${data}
    </a>
  `;
  else return data;
}

function renderReviewAuthor(data, type, row) {
  return '<a class="link" href="' + row.author_link + '" target="_blank">' + data + '</a>';
}

function renderReviewTitle(data, type, row) {
  var html = '<a class="link" href="' + row.review_link + '" target="_blank">' + data + '</a>';
  if (_.includes([true, 'true'], row.verified_purchase)) {
    html += '<br/><span class="block mt-2 text-xs font-semibold text-text/50">Verified Purchase</span>';
  }
  return html;
}

function renderProductName(data, type, row) {
  var html = [];
  html.push(data);
  if (row.track_amazon_com && row.track_amazon_com === 'TRUE') {
    html.push(
      '<br/><span class="px-2 py-0.5 text-2xs uppercase font-semibold bg-accent-3 text-bg rounded-xl align-middle">Monitored</span>',
    );
  }
  return html.join('');
}

function renderProductLink(data, type, row) {
  return window.location.origin + '/accounts/' + row.account_token + '/products/' + data;
}

function renderProductThumbnail(data, type, row) {
  const url = row.main_image_thumb;
  const mirror = row.main_image_thumb_mirror;
  if (!url) return '';

  const alt = row.asin_name || row.amazon_product_title;
  return `
    <img
      class="my-[calc(-0.5rem+1px)] w-12 h-12 min-w-12 object-contain rounded border border-border"
      src="${mirror}"
      onerror="this.src='${url}'"
      alt="${alt}" />
  `;
}

function renderProductReview(data, type, row) {
  if (!row.total_review_count) return '-';
  return `${row.average_customer_review}⭐ <span class="text-sm opacity-50">(${row.total_review_count})</span>`;
}

function renderCheckmark(value) {
  if (value) return '✅';
  return '❌'; // some options: ❌⛔🚫🙅‍♂️
}

function renderCheckmarkCount(count) {
  if (count) return `✅ <span class="text-sm opacity-50">(${count})</span>`;
  return '❌';
}

function renderMetricChangePercent(data) {
  const numeric = typeof data === 'string' ? parseFloat(data) : data;
  if (numeric) {
    if (data >= 0) {
      return '<span class="metric-change good">+' + renderPercent(data) + '</span>';
    } else {
      return '<span class="metric-change bad">' + renderPercent(data) + '</span>';
    }
  } else {
    return '-';
  }
}

function renderMetricChangeNumber(data) {
  const numeric = typeof data === 'string' ? parseFloat(data) : data;
  if (numeric) {
    if (data >= 0) {
      return '<span class="metric-change good">+' + renderNumber(data) + '</span>';
    } else {
      return '<span class="metric-change bad">' + renderNumber(data) + '</span>';
    }
  } else {
    return '-';
  }
}

function renderNumberWithMetricChangePercent(number, percent) {
  if (percent) {
    return `
      <span class="flex gap-4 justify-between">
        <span>${renderNumber(number)}</span>
        ${renderMetricChangePercent(percent)}
      <span>
    `;
  } else {
    return renderNumber(number);
  }
}

function parseJsonArray(jsonString) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return [];
  }
}

export default {
  asinLink,
  renderAsinLink,
  renderTitle,
  renderDollar,
  renderDollarShort,
  renderNumber,
  renderNumberShort,
  renderPercent,
  renderPercentScaled,
  renderDate,
  renderIssueType,
  renderTag,
  renderTagUnescaped,
  renderAttribute,
  renderAttributeAction,
  renderSeller,
  renderReviewAuthor,
  renderReviewTitle,
  renderProductName,
  renderProductLink,
  renderProductThumbnail,
  renderProductReview,
  renderCheckmark,
  renderCheckmarkCount,
  renderMetricChangePercent,
  renderMetricChangeNumber,
  renderNumberWithMetricChangePercent,
  parseJsonArray,
};
