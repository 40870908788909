import React from "react";
import axios from "axios";
import renderHelpers from "../shared/render-helpers";
import DataTableDefinition from "../shared/data-table-definition";

class Datatable extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.helpers = renderHelpers;
  }

  componentDidMount() {
    this.$el = $(this.el);
    this.defineTable();
    this.initTable();

    this.$el.on("click", ".js-remove-tag", this.handleRemove);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.result !== this.props.result) {
      this.dataTable.ajax.reload();
    }
  }

  defineTable() {
    const { organization, canUpdate } = this.props;

    this.definition = new DataTableDefinition({
      defaultSortByColumns: ["asin"],
      defaultSortByDirections: ["desc"],
      columns: [
        {
          name: "asin",
          data: "asin",
          render: (data, type, row) => {
            const link = this.helpers.asinLink(`/accounts/${organization.token}/products/asin`, data);
            return '<a class="link" href="' + link + '">' + data + "</a>";
          },
        },
        {
          name: "asin_name",
          data: "asin_name",
        },
        {
          name: "upc",
          data: "upc",
        },
        {
          name: "model_number",
          data: "model_number",
        },
        {
          name: "replenishment_code",
          data: "replenishment_code",
        },
        {
          name: "remove",
          data: "token",
          render: (data, type, row) => {
            if (canUpdate) {
              return `
                <button class="button secondary bordered small compact js-remove-tag" data-product-token="${data}">
                  <svg class="pointer-events-none" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 8H20L18 20H6L4 8Z" stroke="currentColor"/>
                    <path d="M14 5V3H10V5" stroke="currentColor"/>
                    <path d="M4 5H20" stroke="currentColor"/>
                    <path d="M12 10V18" stroke="currentColor"/>
                    <path d="M15 10V18" stroke="currentColor"/>
                    <path d="M9 10V18" stroke="currentColor"/>
                  </svg>
                </button>
              `;
            } else {
              return "";
            }
          },
          sortable: false,
          ordering: false,
        },
      ],
    });
  }

  initTable() {
    const { organization, tag } = this.props;

    const buttons = [];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS("general", "export_tables")) {
      buttons.push({
        extend: "ilExports",
        filename: `tag-products-${tag.name.replace(/[^a-z0-9]/gi, "-").toLowerCase()}`,
      });
    }

    this.dataTable = this.$el.DataTable({
      paging: true,
      pageLength: 25,
      ajax: {
        type: "GET",
        url: `/accounts/${organization.token}/settings/tags/${tag.token}/show_products`,
        dataSrc: "",
        // dataSrc: (json) => { // Good for debugging what was returned
        //   return json;
        // },
      },
      dom: "<'dataTables_header border-b-0'fB><'dataTables_body border-b border-border'i><'dataTables_body'tr><'dataTables_footer'p>",
      language: TORO.shared.dataTableDefaults.language("products"),
      buttons: buttons,
      order: this.definition.order(),
      columns: this.definition.columns,
    });

    this.dataTable.on("buttons-action", this.handleButtonAction);
  }

  handleRemove = (e) => {
    const $target = $(e.target);
    const productToken = $target.attr("data-product-token");
    const { organization, tag } = this.props;

    e.preventDefault();

    const data = {
      product_tag: {
        product_token: productToken,
        tag_id: tag.id,
      },
    };

    axios.delete(`/accounts/${organization.token}/product_tags/${tag.id}`, { data: data }).then((response) => {
      this.dataTable.row($(e.target).parents("tr")).remove().draw();
    });

    this.mixpanelRemove(productToken);
  };

  handleButtonAction = (e, buttonApi, dataTable, node, config) => {
    // csv export button clicked.
    if (buttonApi.node().hasClass("buttons-csv")) {
      this.mixpanelExport();
    }
  };

  mixpanelRemove(productToken) {
    const { token, name } = this.props.organization;
    const { tag } = this.props;
    mixpanel.track("Tag - Remove Product", {
      "Organization Token": token,
      "Organization Name": name,
      Tag: tag,
      "Product Token": productToken,
    });
  }

  mixpanelExport() {
    const { token, name } = this.props.organization;
    const { tag } = this.props;
    mixpanel.track("Tag - Export Products", {
      "Organization Token": token,
      "Organization Name": name,
      Tag: tag,
    });
  }

  render() {
    return (
      <div>
        <table className="table" ref={(el) => (this.el = el)}>
          <thead>
            <tr>
              <th>ASIN</th>
              <th>Name</th>
              <th>UPC</th>
              <th>Model/Style Number</th>
              <th>Replenishment Code</th>
              <th></th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    );
  }
}

export default Datatable;
