import renderHelpers from '../shared/render-helpers';

class WeeklyReportsShippedGraph {
  constructor(rootSelector) {
    this.$weeklyReportsGraph = $(rootSelector);
    this.helpers = renderHelpers;
  }
  init() {
    const self = this;

    const allData = JSON.parse(this.$weeklyReportsGraph.attr('data-weekly-data'));
    const data = _.map(allData, function (item) {
      return parseInt(item['current']);
    });
    const lastYearData = _.map(allData, function (item) {
      return parseInt(item['last']);
    });
    const weekdate = _.map(allData, function (item) {
      return item['week'];
    });
    const label = this.$weeklyReportsGraph.attr('data-label');
    const currentYear = this.$weeklyReportsGraph.attr('data-current-year');

    Highcharts.chart(this.$weeklyReportsGraph.attr('id'), {
      // title: false,
      title: {
        text: 'Weekly Ordered Product Sales',
      },
      xAxis: {
        type: 'category',
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
        title: {
          enabled: true,
          text: 'Weeks',
        },
        categories: weekdate,
      },
      yAxis: {
        title: {
          text: label,
        },
        labels: {
          formatter: function () {
            return self.helpers.renderDollarShort(this.value);
          },
        },
        min: 0,
      },
      exporting: {
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                enabled: false,
              },
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          return '<b>' + label + '</b><br/>' + this.x + ': ' + self.helpers.renderDollar(this.y);
        },
      },
      series: [
        {
          type: 'line',
          name: currentYear + ' ' + label,
          data: data,
          dataLabels: {
            zIndex: 10,
          },
          zIndex: 2,
        },
        {
          type: 'line',
          name: currentYear - 1 + ' ' + label,
          data: lastYearData,
          dataLabels: {
            zIndex: 9,
          },
          zIndex: 1,
        },
      ],
    });
  }
}

export default WeeklyReportsShippedGraph;
