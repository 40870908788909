import renderHelpers from '../shared/render-helpers';

class MonthlyReportsShippedGraph {
  constructor(rootSelector) {
    this.$monthlyReportsGraph = $(rootSelector);
    this.helpers = renderHelpers;
  }
  init() {
    const self = this;

    const allData = JSON.parse(this.$monthlyReportsGraph.attr('data-monthly-data'));
    const data = _.map(allData, function (item) {
      return parseInt(item['current']);
    });
    const lastYearData = _.map(allData, function (item) {
      return parseInt(item['last']);
    });
    const label = this.$monthlyReportsGraph.attr('data-label');
    const currentYear = this.$monthlyReportsGraph.attr('data-current-year');

    Highcharts.chart(this.$monthlyReportsGraph.attr('id'), {
      // title: false,
      title: {
        text: 'Monthly Ordered Product Sales',
      },
      xAxis: {
        type: 'category',
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
        title: {
          enabled: true,
          text: 'Month',
        },
        categories: TORO.shared.months,
      },
      yAxis: {
        title: {
          text: label,
        },
        labels: {
          formatter: function () {
            return self.helpers.renderDollarShort(this.value);
          },
        },
        min: 0,
      },
      exporting: {
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                allowOverlap: true,
                style: {
                  fontSize: '8px',
                },
                formatter: function () {
                  return self.helpers.renderDollar(this.y);
                },
              },
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          return '<b>' + label + '</b><br/>' + this.x + ': ' + self.helpers.renderDollar(this.y);
        },
      },
      series: [
        {
          type: 'line',
          name: currentYear + ' ' + label,
          data: data,
          dataLabels: {
            zIndex: 10,
          },
          zIndex: 2,
        },
        {
          type: 'line',
          name: currentYear - 1 + ' ' + label,
          data: lastYearData,
          dataLabels: {
            zIndex: 9,
          },
          zIndex: 1,
        },
      ],
    });
  }
}

export default MonthlyReportsShippedGraph;
